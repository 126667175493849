var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-navigation-drawer',{staticStyle:{"top":"0px","max-height":"100vh!important"},attrs:{"right":"","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_c('filterCalendar',{attrs:{"company":_vm.company},on:{"filtersCalendar":_vm.filtersCalendar}})],1),_c('v-data-table',{staticClass:"elevation-0 px-6 py-4",attrs:{"headers":_vm.headers,"items":_vm.calendars},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Actividades")]),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":_vm.openFilter}},[_vm._v("mdi-filter")])],1),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('download')),expression:"permissions('download')"}],attrs:{"icon":""}},[_c('v-icon',{on:{"click":_vm.exportExcel}},[_vm._v("mdi-download")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permissions('editActivities')==true || _vm.permissions('deleteActivities')==true)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"font-size":"13px"}},[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('editActivities')),expression:"permissions('editActivities')"}],on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Editar ")],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('deleteActivities')),expression:"permissions('deleteActivities')"}],on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Eliminar ")],1)],1)],1):_vm._e()]}},{key:"item.activity",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"pa-2",attrs:{"outlined":"","small":"","color":_vm.color(item.activity)}},[_vm._v(_vm._s(item.activity))])]}},{key:"item.completed",fn:function(ref){
var item = ref.item;
return [(item.date<=new Date() && item.completed==0)?_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.status(item.id,1)}}},[_vm._v(" mdi-close ")]):((item.completed==1))?_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.status(item.id,0)}}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.status(item.id,1)}}},[_vm._v(" mdi-calendar-clock")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.slice(0, 10))+" ")]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",staticStyle:{"min-height":"0px!important","font-size":"14px"},attrs:{"to":{ path: '/clients/client/'+ item.companyID}}},[_vm._v(_vm._s(item.company))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at.slice(0, 10))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No existen registros de actividades aún ")]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","color":"#e74919","dark":"","fab":"","fixed":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('create',{attrs:{"company":_vm.company},on:{"closeCreateDialogCalendar":_vm.closeCreateDialogCalendar}})],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('edit',{attrs:{"calendar":_vm.calendar},on:{"closeDialogEditCalendar":_vm.closeDialogEditCalendar}})],1),_c('div',{staticClass:"text-center"},[_c('v-bottom-sheet',{attrs:{"inset":""},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"150px"}},[_c('div',{staticClass:"pt-6"},[_vm._v(" ¿Seguro que deseas borrar esta actividad? ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteCalendar()}}},[_vm._v(" Eliminar ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"grey"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }