<template>
    <v-row class="pa-6">
        <!-- Detalle de Empresa -->
        <v-data-table v-show="company!=undefined" :headers="headers" :items="shippingDetail" class="elevation-0 px-6 py-4" dense style="width: 100%!important;">
            <!-- Header -->
            <template v-slot:top>
                <v-toolbar flat >
                    <v-toolbar-title>Envios</v-toolbar-title>
                    <!--v-btn icon>
                        <v-icon @click="openFilter">mdi-filter</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon>
                        <v-icon @click="exportExcel">mdi-download</v-icon>
                    </v-btn-->
                </v-toolbar>
            </template>
            <template v-slot:[`item.completed`]="{ item }">
                <v-icon v-if="item.completed==true">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>
            <!-- Tabla sin información -->
            <template v-slot:no-data>
                No existen registros de envíos aún
            </template>
            <!-- Creación -->
            <template v-slot:[`item.created_at`]="{ item }">
                {{item.created_at.slice(0, 10)}}
            </template>
            <!-- Actualización -->
            <template v-slot:[`item.updated_at`]="{ item }">
                {{item.updated_at.slice(0, 10)}}
            </template>
                <!-- Acciones por fila -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left v-if="(permissions('editShippingOrder')==true || permissions('deleteShippingOrder')==true) && item.completed==false">
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template>
                    <v-list style="font-size:13px;">
                        <v-list-item @click="editItem(item.id)"  v-show="permissions('editShippingOrder')">
                            <v-icon small class="mr-2">
                                mdi-pencil
                            </v-icon>
                            Editar
                        </v-list-item>
                        <v-list-item @click="deleteItem(item)"  v-show="permissions('deleteShippingOrder')">
                            <v-icon small class="mr-2">
                                mdi-delete
                            </v-icon>
                            Eliminar
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>

        <!-- Normal -->
        <v-col v-show="company==undefined" cols="12" sm="6" md="10">
            <v-data-table :headers="headers" :items="shippingDetail" class="elevation-0" style="background: #f7f7f7;!important" dense>
                <template v-slot:[`item.completed`]="{ item }">
                    <v-icon v-if="item.completed==true">mdi-check</v-icon>

                    <v-menu v-else offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <div class="pa-2" style="background:white;">
                            <strong style="font-size:12px;">Adjunta factura para continuar</strong>
                            <vue-dropzone class="mb-2" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true" v-on:vdropzone-success="uploadSuccess" v-on:vdropzone-error="uploadError" v-on:vdropzone-removed-file="fileRemoved"/>
                            <v-btn @click="saveCompleted(item.id)" :disabled="fileName==''" small rounded color="primary" class="elevation-0"> Guardar</v-btn>
                        </div>
                    </v-menu>


                </template>
                <!-- Tabla sin información -->
                <template v-slot:no-data>
                    No existen registros de envíos aún
                </template>
                <!-- Pdf -->
                <template v-slot:[`item.pdf`]="{ item }">
                    <v-btn v-if="item.pdf!=undefined" v-bind:href="'https://intenbackend.unocrm.mx/files/' + item.pdf" target="_blank" icon>
                        <v-icon small>mdi-download</v-icon>
                    </v-btn>
                </template>
                <!-- Creación -->
                <template v-slot:[`item.created_at`]="{ item }">
                    {{item.created_at.slice(0, 10)}}
                </template>
                <!-- Actualización -->
                <template v-slot:[`item.updated_at`]="{ item }">
                    {{item.updated_at.slice(0, 10)}}
                </template>
                 <!-- Acciones por fila -->
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu bottom left v-if="(permissions('editShippingOrder')==true || permissions('deleteShippingOrder')==true) && item.completed==false">
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                        </template>
                        <v-list style="font-size:13px;">
                            <v-list-item @click="editItem(item.id)"  v-show="permissions('editShippingOrder')">
                                <v-icon small class="mr-2">
                                    mdi-pencil
                                </v-icon>
                                Editar
                            </v-list-item>
                            <v-list-item @click="deleteItem(item)"  v-show="permissions('deleteShippingOrder')">
                                <v-icon small class="mr-2">
                                    mdi-delete
                                </v-icon>
                                Eliminar
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-col>
        <v-col v-show="company==undefined" cols="12" sm="6" md="2">
            <v-btn @click="addDialog=true" color="primary" rounded class="elevation-0">Agregar</v-btn>
        </v-col>
        <!-- Crear envio -->
        <v-dialog v-model="addDialog" max-width="700px">
          <add @closeDialogAddShipping="closeDialogAddShipping" v-bind:shippingId="shipping"/>
        </v-dialog>
        <!-- Editar envio -->
        <v-dialog v-model="editDialog" max-width="700px">
          <edit v-bind:shippingOrder="shippingOrder" @closeDialogEditShipping="closeDialogEditShipping"/>
        </v-dialog>
        <!-- Dialogo confirmación de eliminación -->
        <div class="text-center">
            <v-bottom-sheet  v-model="sheet" inset>
                <v-sheet class="text-center" height="150px">
                    <div class="pt-6">
                    ¿Seguro que deseas borrar esta orden del envío con folio {{shipping}}?
                    </div>
                    <v-btn class="mt-4" text color="error" @click="deleteShipping()">
                    Eliminar
                    </v-btn>
                    <v-btn class="mt-4" text color="grey" @click="cancel()">
                    Cancelar
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>
        <v-snackbar :color="snackbar.color" v-model="snackbar.show">
            {{ snackbar.message }}
        </v-snackbar>
    </v-row>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import axios from "axios";
    import Add from "../detail/add"
    import Edit from "../detail/edit"
    export default {
        props:{
            shipping:Number,
            company:String
        },
        components: {
            vueDropzone: vue2Dropzone,
            'add':Add,
            'edit':Edit,
        },
        data: () => ({
            completedId:'',
            dropzoneOptions: {
                url: "https://intenbackend.unocrm.mx/api/v1/shipping_detail/files/",
                addRemoveLinks: true,
                maxFiles: 1
            },
            fileName:'',
            addDialog:false,
            editDialog:false,
            sheet:false,
            deleteId:'',
            shippingOrder:'',
            snackbar: {
                show: false,
                message: null,
                color: null
            },
        }),
        computed: {
            currentUser:{
                get(){
                    return this.$store.state.currentUser.user;
                }
            },
            shippingsList(){
                return this.$store.state.shipping_detail.shipping_details;
            },
            shippingDetail(){
                var shipDet = this.$store.state.shipping_detail.shipping_details.map(id=>{
                    return{
                        company_id:this.companyId(id.sale_id),
                        shipping_id:id.shipping_id,
                        id:id.id,
                        sale: this.sale(id.sale_id),
                        //shipping: 'E-' + id.shipping_id,
                        completed: id.completed,
                        invoice: id.invoice,
                        //driver: this.driver(id.shipping_id),
                        weight: this.weight(id.sale_id),
                        due: this.due(id.sale_id),
                        //pay: id.hola,
                        created_by_user_id: id.created_by_user_id,
                        created_at: id.created_at,
                        last_updated_by_user_id: id.last_updated_by_user_id,
                        updated_at: id.updated_at,
                        pdf:id.pdf
                    }
                });
                if(this.shipping!='' && this.shipping!=undefined && this.shipping!=null){
                    shipDet = shipDet.filter(shipping=>shipping.shipping_id == this.shipping)
                }
                if(this.company!='' && this.company!=undefined && this.company!=null){
                    shipDet = shipDet.filter(shipping=>shipping.company_id == this.company)
                }
                return  shipDet
            },
            headers(){ 
                return [
                { text: 'Venta', value: 'sale' },
                //{ text: 'Envio', value: 'shipping' },
                { text: 'Entregado', value: 'completed' },
                { text: 'Factura', value: 'invoice' },
                { text: 'PDF', value: 'pdf' },
                //{ text: 'Chofer', value: 'driver' },
                { text: 'Peso', value: 'weight' },
                { text: 'Monto por cobrar', value: 'due' },
                /*{ text: 'Monto cobrado', value: 'pay' },
                { text: 'Edita', value: 'created_by_user_id' },
                { text: 'Edicion', value: 'updated_at' },
                { text: 'Crea', value: 'last_updated_by_user_id' },
                { text: 'Cración', value: 'created_at' },*/
                { value: 'actions', sortable: false, align: 'end', },
            ]},
        },
        methods:{
            saveCompleted(id){
                var editedItem = this.$store.state.shipping_detail.shipping_details.filter(shipping=>shipping.id == id)[0]
                editedItem.last_updated_by_user_id=this.currentUser.id;
                editedItem.pdf=this.fileName;
                editedItem.completed=true;
                axios.put("https://intenbackend.unocrm.mx/api/v1/shipping_detail/update",Object.assign(editedItem)).then(response=>{
                    this.snackbar = {
                        message: 'Envío Completado',
                        color: 'success',
                        show: true
                    }
                }).catch(error => {
                    this.snackbar = {
                        message: error.response.data.message,
                        color: 'error',
                        show: true
                    }
                })
            },
            uploadSuccess(file, response) {
                console.log('File Successfully Uploaded with file name: ' + response.file);
                this.fileName = response.file;
            },
            uploadError(file, message) {
                console.log('An Error Occurred');
            },
            fileRemoved() {
                this.fileName = ''
            },
            companyId(saleId){
                return this.$store.state.quotation.quotations.filter(quotation=>quotation.status == 'vendido').filter(quotation=>quotation.id==saleId).map(quotation=>quotation.company_id)
            },
            permissions(permission){
                if(this.currentUser.id==1){
                    return true
                }else if(this.currentUser.permissions!=undefined){
                    if(this.currentUser.permissions.includes(permission)){
                        return true
                    }else{
                        return false
                    }
                }else{
                    return false
                }
            },
            sale(id){
                var saleName = this.$store.state.quotation.quotations.filter(quotation=>quotation.status == 'vendido').filter(quotation=>quotation.id==id).map(id=>{
                    return{
                        name: 'id:' + id.id + this.companyName(id.company_id) + this.contact(id.contact_id)
                    }
                })
                return saleName[0].name
            },
            driver(id){
                var driver = this.$store.state.shipping.shippings.filter(shipping=>shipping.id==id).map(shipping=>shipping.driver_id)[0]
                return this.$store.state.user.users.filter(user=>user.id==driver).map(user=>user.name + ' ' + user.last)[0]
            },
            weight(id){
                var items = this.$store.state.quotation.quotations.filter(quotation=>quotation.status == 'vendido').filter(quotation=>quotation.id==id).map(quotation=>quotation.items)[0]
                var weight = 0
                for(var i=0; i<items.length; i++){
                    weight = weight + (items[i].weight*items[i].quantity)
                }
                return weight + ' kg'
            },
            due(id){
                var sale = this.$store.state.quotation.quotations.filter(quotation=>quotation.status == 'vendido').filter(quotation=>quotation.id==id)[0]
                var collections = this.$store.state.collection.collections.filter(collection=>collection.quotation_id==sale.id)
                var payments = 0 
                for(var i=0; i<collections.length; i++){
                    payments = payments + (collections[i].amount*1)
                }
                var client = this.$store.state.company.companies.filter(company=>company.id==sale.company_id).map(company=>company.credit_days)[0]
                var due = sale.total - payments 
                if((client == 0 || client == null || client == undefined || client == '' )&& due>0){
                    return sale.total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})
                }
            },
            companyName(id){
                if(id!=null && id!=undefined && id!=''){
                    return ' | ' + this.$store.state.company.companies.filter(company=>company.id == id).map(company=>company.name)[0]
                }
            },
            contact(id){
                if(id!=null && id!=undefined && id!=''){
                    return ' | ' + this.$store.state.contact.contacts.filter(contact=>contact.id == id).map(contact=>contact.name)[0]
                }
            },
            deleteItem (item) {
                this.deleteId = item.id
                this.sheet = true
            },
            deleteShipping(){
                axios.delete("https://intenbackend.unocrm.mx/api/v1/shipping_detail/delete/"+this.deleteId).then(response => {
                    this.deleteId = ''
                    this.sheet = false
                    this.$store.dispatch('shipping_detail/getShippingDetails')
                }).catch(error => {
                    this.snackbar = {
                        message: error.response.data.message,
                        color: 'error',
                        show: true
                    }
                });
            },
            cancel(){
                this.deleteId = ''
                this.sheet = false
            },
            editItem(id){
                this.shippingOrder = this.shippingsList.filter(shipping=>shipping.id == id)[0]
                this.editDialog = true
            },
            closeDialogEditShipping: function(params) {
                this.editDialog = false;
                this.$store.dispatch('shipping_detail/getShippingDetails')
            },
            closeDialogAddShipping: function(params) {
                this.addDialog = false;
                this.$store.dispatch('shipping_detail/getShippingDetails')
            },
        }
    }
</script>